<template>
  <div class="px-8">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card class="dialog-card px-10">
        <v-row class="mt-4">
          <v-col>
            <h1 class="secondary--text">Upload de Movimentação</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <v-autocomplete
              v-model="financialGroupId"
              :items="financialGroups"
              @input="getLayoutByFinancialGroup('filter')"
              dense
              item-text="name"
              item-value="id"
              outlined
              hide-details
              label="Grupo Financeiro"
              placeholder="Selecione o grupo financeiro"
              color="textPrimary"
              item-color="textPrimary"
              append-icon="fas fa-chevron-down"
            />
          </v-col>
        </v-row>

        <v-form ref="updateForm">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headerLayouts"
                :items="layouts"
                :items-per-page="10"
                :loading="loadingLayoutTable"
                item-key="id"
                class="elevation-1"
                hide-default-footer
                :height="layouts && layouts.length > 5 ? '288px' : ''"
              >
                <template v-slot:[`item.fileType`]="{ item }">
                  {{ item.fileType === 'XLS' ?  'XLSX' : item.fileType }}
                </template>
                <template v-slot:[`item.financialGroupName`]="{ item }">
                  {{ item.financialGroupName ? item.financialGroupName : '-' }}
                </template>
                <template v-slot:[`item.contractName`]="{ item }">
                  {{ item.contractName ? item.contractName : '-' }}
                </template>
                <template v-slot:[`item.subContractName`]="{ item }">
                  {{ item.subContractName ? item.subContractName : '-' }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-file-input
                          class="mt-0 pt-0"
                          v-model="item.file"
                          @change="readFileContent(item)"
                          prepend-icon="mdi-file-upload"
                          accept="text/plain, .csv, .xlsx"
                          hide-input
                        />
                      </div>
                    </template>
                    <span>{{ item.file === null ? 'Enviar arquivo' : 'Arquivo enviado' }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div class="float-right pt-2">
                <v-pagination
                  v-model="page"
                  color="textPrimary"
                  :length="totalPages"
                  :total-visible="9"
                  :disabled="loadingLayoutTable"
                  @input="getLayoutByFinancialGroup('pagination')"
                />
              </div>
            </v-col>

            <SuccessAlert class="px-3" :message="successAlertMessage" :successAlertActive="successAlertActive" />

            <v-col cols="12" align="end">
              <v-btn
                large
                outlined
                color="textPrimary"
                width="200px"
                @click="close()"
              >
                Fechar
              </v-btn>
            </v-col>
          </v-row>

          <v-col class="text-center">
            <v-snackbar
              v-model="snackbar.show"
              timeout="10000"
              top
              elevation="24"
              :color="snackbar.type"
            >
              <div class="text-center">{{ snackbar.text }}</div>
              <template v-slot:action="{ attrs }">
                <v-icon
                  text
                  dark
                  v-bind="attrs"
                  @click="snackbar.show = false"
                >
                  close
                </v-icon>
              </template>
            </v-snackbar>
          </v-col>

          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="120">
              <div>Carregando...</div>
            </v-progress-circular>
          </v-overlay>
        </v-form>
      </v-card>
    </v-dialog>
    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="800" :heightCardValue="800">
      <div slot="title">
        <v-row>
          <v-col cols="12">
            <span color="primary"> Erros durante o upload </span>
          </v-col>
        </v-row>
      </div>
      <div slot="textInfo">
        <v-row>
          <v-col cols="12">
            <span> Por favor verifique os erros abaixo: </span>
            <div v-for="(error, index) in validationsErros" :key="index">
              <h5 class="mt-2 font-weight-regular" style="word-break: break-word"> <span class="red--text"> {{ '* ' }} </span> {{ error }}</h5>
            </div>
          </v-col>
        </v-row>
      </div>
    </DynamicDialogInformation>
  </div>
</template>

<script>
import LayoutService from '@/services-http/sdi/LayoutService';
import MovementFileService from '@/services-http/sdi/MovementFileService';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import SuccessAlert from '@/components/CustomAlerts/SuccessAlert.vue';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import Rules from '@/shared/validators/formRules';

export default ({
  name: 'MovementUploadModal',
  components: { SuccessAlert, DynamicDialogInformation },
  data: () => ({
    financialGroups: [],
    financialGroupId: '',
    dialog: false,
    loadingLayoutTable: false,
    page: 1,
    totalPages: 0,
    codeMessage: '',
    isSuccess: false,
    overlay: false,
    layouts: [],
    snackbar: {
      show: false,
      type: '',
      text: '',
    },

    headerLayouts: [
      {
        text: 'Nome',
        value: 'name',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo de Arquivo',
        value: 'fileType',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupName',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'contractName',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Sub-Contrato',
        value: 'subContractName',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        align: 'start',
        value: 'actions',
        class: 'text--truncate',
        sortable: false,
      },
    ],
    
    validationsErros: [],
    successAlertActive: false,
    successAlertMessage: '',
  }),

  props: { financialGroupsProps: Array },

  watch: {
    financialGroupsProps: {
      deep: true,
      handler(val) {
        this.financialGroups = val;
        this.verifyAmountFinancialGroups();
      },
    },
  },

  mixins: [
    VerifyRoutesMixin,
  ],

  methods: {
    open() {
      this.dialog = true;
      if (this.financialGroupId) {
        this.getLayoutByFinancialGroup('filter');
      }
    },
    close() {
      this.dialog = false;
      this.successAlertActive = false;
      this.$refs.updateForm.reset();
    },
    async verifyAmountFinancialGroups() {
      if (this.financialGroups.length === 1) {
        this.financialGroupId = this.financialGroups[0].id;
        this.getLayoutByFinancialGroup('filter');
      }
    },
    getLayoutByFinancialGroup(type) {
      if (this.financialGroupId) {
        this.loadingLayoutTable = true;
        this.layouts = [];
        if (type === 'filter') this.page = 1;
        const paramsObject = {
          financialGroupId: this.financialGroupId,
          entityType: 'FINANCIAL_GROUP',
          typeIntegrationValue: 'RECEIVEMENT',
          collectorType: 'FTP',
          status: 'ENABLED',
          collectorDeployed: true,
          page: this.page - 1,
          size: 10,
        };
        this.layoutService.FindAllByFilter(paramsObject).then((response) => {
          if (response && response.data.content.length > 0) {
            this.layouts = response.data.content;
            this.totalPages = response.data.totalPages;
            this.addIndexInArrayLayouts();
          }
          this.loadingLayoutTable = false;
        }).catch(() => {
          this.loadingLayoutTable = false;
        });
      }
    },
    addIndexInArrayLayouts() {
      const layout = this.layouts.map((element) => ({
        ...element,
        file: null,
      }));
      this.layouts = layout;
    },
    readFileContent(item) {
      if (item && item.file && item.file !== null) {
        const ext = item.file.name.substr(item.file.name.lastIndexOf('.') + 1).toUpperCase();
        switch (item.fileType) {
          case 'XLS':
            if (ext && ext === 'XLSX') {
              this.uploadMovementFile(item.id, item.file);
            } else {
              this.customizeSnackbarMessage('error', `O tipo do arquivo esperado é ${item.fileType}X`);
              // eslint-disable-next-line no-param-reassign
              item.file = null;
            }
            break;

          case 'CSV':
          case 'XML':
          case 'TXT':
            if (ext && ext === item.fileType) {
              this.uploadMovementFile(item.id, item.file);
            } else {
              this.customizeSnackbarMessage('error', `O tipo do arquivo esperado é ${item.fileType}`);
              // eslint-disable-next-line no-param-reassign
              item.file = null;
            }
            break;

          default:
            break;
        }
      }
    },
    uploadMovementFile(layoutId, file) {
      this.overlay = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('layoutId', layoutId);
      this.validationsErros = this.validateSizeAndFileExtension(file);
      if (this.validationsErros.length > 0) {
        this.overlay = false;
        this.successAlertActive = false;
        this.$refs.updateForm.reset();
        this.$refs.DynamicDialogInformation.open();
      } else {
        this.movementFileService.Upload(formData).then(() => {
          this.overlay = false;
          this.successAlertActive = true;
          this.successAlertMessage = 'O arquivo foi recebido e será apresentado em alguns instantes na lista de protocolos.';
          this.$refs.updateForm.reset();
          setTimeout(() => {
            this.successAlertActive = false;
          }, 10000);
          this.$refs.updateForm.reset();
          }).catch(() => {
            this.overlay = false;
            this.successAlertActive = false;
            this.$refs.updateForm.reset();
          });
      }      
    },
    validateSizeAndFileExtension(file) {
      const allowedExtensions = ['xml', 'txt', 'csv', 'xls', 'xlsx'];
      const extensionErrors = [];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');

      let message;
      if (!this.rules.isValidFileSize(file.size)) {
        message = `O arquivo ${fileName} ultrapassa o limite permitido de ${this.rules.getMaxFileSizeMB()} .`;
        extensionErrors.push(message);
      }

      if (lastDotIndex === -1) {
        message = `O arquivo ${fileName} é inválido, pois o mesmo não possui extensão`;
        extensionErrors.push(message);
      }

      const fileExtension = fileName.substring(lastDotIndex + 1).toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        message = `A extensão do arquivo ${fileName} não é valida. As extensões permitidas são: ${allowedExtensions.join(', ')}`;
        extensionErrors.push(message);
      }

      return extensionErrors;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },

  created() {
    this.layoutService = new LayoutService();
    this.movementFileService = new MovementFileService();
    this.rules = new Rules();
  },
});
</script>

<style scoped>
.dialog-card {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  top: 35%;
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1600px) and (min-width: 1199px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .dialog-card {
    width: 70%;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dialog-card {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .dialog-card {
    width: 90%;
  }
}
</style>
